import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { StatsCard } from "@/game-val/LiveMatch.style.jsx";
import { getQueueName } from "@/game-val/utils.mjs";
import { calcRate } from "@/util/helpers.mjs";

const StatsContainer = styled(StatsCard)`
  display: flex;
  flex-direction: column;
  .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
  }
`;

const LiveMatchRecentStats = ({ queue, stats }) => {
  const { t } = useTranslation();
  const kd = stats ? calcRate(stats.kills, stats.deaths, 2) : "-";
  const avgKills = stats ? calcRate(stats.kills, stats.matchesPlayed, 1) : "-";

  return (
    <StatsContainer>
      <p className="type-body2 title">
        {t(
          "val:recentNDeathmatchStats",
          "Recent {{numberOfGames}} {{queue}} Stats",
          {
            numberOfGames: stats?.matchesPlayed || 0,
            queue: getQueueName(queue),
          },
        )}
      </p>
      <p className="type-caption--bold stats">
        <span className="type-caption caption">
          {t("common:stats.killsDeaths", "Kills/Death")}
        </span>
        <span className="stat">{kd}</span>
      </p>
      <p className="type-caption--bold stats">
        <span className="type-caption caption">
          {t("val:avgKills", "Avg. Kills")}
        </span>
        <span className="stat">{avgKills}</span>
      </p>
    </StatsContainer>
  );
};

export default LiveMatchRecentStats;
