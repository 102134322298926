import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { AgentImage } from "@/game-val/CommonComponent.jsx";
import { LIVE_COMPARISON_OPTIONS } from "@/game-val/constants.mjs";
import MapStats from "@/game-val/LiveMatchMapStats.jsx";
import LiveMatchRecentStats from "@/game-val/LiveMatchRecentStats.jsx";
import TeamBreakdown from "@/game-val/LiveMatchTeamBreakdown.jsx";
import {
  calcAvg,
  getAgentImage,
  getNameTag,
  getQueueName,
  isQueueDeathmatch,
  isQueueTeamDeathmatch,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import WinPrediction from "@/game-val/WinPrediction.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import {
  MainColumnsContainer,
  MatchPageContainer,
} from "@/shared/Match.style.jsx";
import SharedStatsBreakdown from "@/shared/StatsBreakdown.jsx";
import { Timer } from "@/shared/Time.jsx";
import { formatOrdinalNumber } from "@/util/i18n-helper.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const AgentImageContainer = styled("div")`
  --border-color: ${(props) => props.$color};
  box-sizing: border-box;
  margin-right: var(--sp-3);
  width: var(--sp-6);
  height: var(--sp-6);
  border-radius: var(--br);
  background: var(--border-color);
  img {
    width: 100%;
    height: 100%;
    border: var(--sp-0_5) solid var(--border-color);
  }
`;

const AIWinPrediction = styled(WinPrediction)`
  position: relative;
  padding: var(--sp-4) var(--sp-6);

  .title {
    background: initial;
    padding: 0;
    height: initial;

    p {
      margin: 0 0 var(--sp-2) 0;
      color: var(--shade1);
      font-size: var(--sp-3_5e);
      font-style: normal;
      font-weight: 500;
      text-transform: initial;
    }
  }

  .content {
    padding: 0;
    background: initial;
  }
`;

const Divider = styled("div")`
  width: 100%;
  height: var(--sp-0_5);
  background: var(--shade9);
`;

const requiredStats = [
  {
    title: ["val:abilityKills", "Ability Kills"],
    key: "abilityKills",
  },
  {
    title: ["val:kills", "Kills"],
    key: "kills",
  },
  {
    title: ["val:assists", "Assists"],
    key: "assists",
  },
  {
    title: ["common:combatScore", "Combat Score"],
    key: "score",
  },
];

const platformPath = getPlatformPath(false); // PC only

function LiveMatch() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);

  const {
    parameters: [nameTag = ""],
  } = useTransientRoute();
  const {
    val: {
      profiles,
      playerMapStats,
      playerActStats,
      latestPlayerStats,
      content: { agents, acts, gameMaps },
      meta: {
        maps: { list: mapsMeta },
      },
      live: { [nameTag.toLowerCase()]: match },
    },
    settings: {
      valorant: { liveMatchComparison },
    },
  } = state;

  const lowercaseName = nameTag.toLowerCase();
  const profile = profiles[lowercaseName];

  const latestActId = acts.find((act) => act.endedAt === null)?.uuid;
  const recentStats = latestPlayerStats[profile?.puuid]?.[match?.queue]?.[0];
  let mapCareerStats =
    playerMapStats[profile?.puuid]?.[latestActId]?.[platformPath]?.[
      match?.queue
    ];
  mapCareerStats =
    mapCareerStats && !(mapCareerStats instanceof Error)
      ? mapCareerStats
      : null;
  const mapData = mapCareerStats?.find(
    (m) => m.gameMap.path === match?.gameMapPath,
  );
  const currentPlayer = match?.players?.find(
    (p) => getNameTag(p.gameName, p.tagLine) === nameTag,
  );
  const agentId = currentPlayer?.agent?.uuid;
  const agentKey = agents
    .find((agentObj) => agentObj.uuid.toLowerCase() === agentId?.toLowerCase())
    ?.name?.toLowerCase()
    ?.replaceAll("/", "");
  const mapKey = match
    ? gameMaps.find((m) => m.path === match?.gameMapPath)?.name?.toLowerCase()
    : null;
  const mapMeta = mapsMeta.find((m) => m.key === mapKey);
  const dot_str = ".";

  const playerTeamId = match?.players?.find(
    (p) => getNameTag(p.gameName, p.tagLine) === nameTag,
  )?.teamId;
  const [playerTeamWinPercent, enemyTeamWinPercent] = useMemo(() => {
    if (!match?.players || !profile) return [0, 0];
    let playerTeamTotal = 0;
    let playerTeamCount = 0;
    let enemyTeamTotal = 0;
    let enemyTeamCount = 0;
    for (const p of match.players) {
      const profile = profiles[getNameTag(p.gameName, p.tagLine)];
      const latestPlayerActStats =
        playerActStats[profile?.puuid]?.[latestActId]?.[platformPath];
      if (!latestPlayerActStats || latestPlayerActStats instanceof Error)
        continue;
      const actStats = latestPlayerActStats?.find(
        (s) => s.queue === match?.queue,
      );
      if (!actStats) continue;
      if (actStats.matchesPlayed > 0) {
        const winPercent = (actStats.matchesWon / actStats.matchesPlayed) * 100;
        if (p.teamId === playerTeamId) {
          playerTeamTotal += winPercent;
          playerTeamCount += 1;
        } else {
          enemyTeamTotal += winPercent;
          enemyTeamCount += 1;
        }
      }
    }
    const playerTeamAvg =
      playerTeamTotal / (playerTeamCount > 0 ? playerTeamCount : 1);
    const enemyTeamAvg =
      enemyTeamTotal / (enemyTeamCount > 0 ? enemyTeamCount : 1);
    const totalAvg = playerTeamAvg + enemyTeamAvg;
    const playerTeamWinPercent =
      (playerTeamAvg / (totalAvg > 0 ? totalAvg : 1)) * 100;
    const enemyTeamWinPercent =
      (enemyTeamAvg / (totalAvg > 0 ? totalAvg : 1)) * 100;
    return [playerTeamWinPercent, enemyTeamWinPercent];
  }, [latestActId, match, playerActStats, playerTeamId, profile, profiles]);

  const lobbyStats = useMemo(() => {
    if (!match?.rounds) return {};
    return match.rounds.reduce((acc, round) => {
      for (const [id, stats] of Object.entries(round.playerStats)) {
        if (!acc[id]) acc[id] = { roundsPlayed: match.rounds.length };
        for (const stat of requiredStats) {
          if (!acc[id][stat.key]) acc[id][stat.key] = 0;
          acc[id][stat.key] += stats[stat.key];
        }
      }
      return acc;
    }, {});
  }, [match?.rounds]);

  const lastNStats = useMemo(() => {
    return requiredStats.reduce(
      (acc, stat) => {
        acc[stat.key] = calcAvg(
          recentStats?.[stat.key] || 0,
          recentStats?.matchesPlayed,
        );
        return acc;
      },
      { roundsPlayed: recentStats?.roundsPlayed || 0 },
    );
  }, [recentStats]);

  const [sections, rowData] = useMemo(() => {
    if (!match?.players || !profile || !lobbyStats || !lastNStats) return [];
    const sections = [
      {
        centerTitleText: t("common:livePerformance", "Live Performance"),
        leftTitleText: t("common:you", "You"),
        rightTitleText: t("common:lobby", "Lobby"),
        comparisonOptions: LIVE_COMPARISON_OPTIONS(recentStats?.matchesPlayed),
        selectedComparison: liveMatchComparison,
        onChangeComparison: (value) =>
          writeSettings(["valorant", "liveMatchComparison"], value),
      },
    ];
    const comparisonStats = requiredStats.map(({ key, title }) => {
      const sortedStats = Object.entries(lobbyStats).sort(
        ([, stats1], [, stats2]) => stats1[key] - stats2[key],
      );
      const enemyTeam =
        match?.players
          ?.filter((p) => p.teamId !== playerTeamId)
          ?.map((p) => p.internalUuid) || [];
      const filteredStats =
        liveMatchComparison === "team"
          ? sortedStats.filter((s) => !enemyTeam.includes(s[0]))
          : sortedStats;
      const yourIndex = filteredStats.findIndex(
        (s) => s[0] === profile?.valorantProfile?.internalUuid,
      );
      const comparisonIndex =
        yourIndex === filteredStats.length - 1
          ? filteredStats.length - 2
          : filteredStats.length - 1;
      const agentId = match?.players?.find(
        (p) => p.internalUuid === filteredStats[comparisonIndex]?.[0],
      )?.agent?.uuid;
      const agentKey = agents
        .find(
          (agentObj) => agentObj.uuid.toLowerCase() === agentId?.toLowerCase(),
        )
        ?.name?.toLowerCase()
        ?.replaceAll("/", "");
      const isEnemy = enemyTeam.includes(filteredStats[comparisonIndex]?.[0]);
      const borderColor = isEnemy ? "var(--red)" : "var(--turq)";
      const isRecentStats =
        LIVE_COMPARISON_OPTIONS()[2].value === liveMatchComparison;
      const dataPoint = filteredStats[yourIndex]?.[1]?.[key];
      const nextDataPoint = !isRecentStats
        ? filteredStats[comparisonIndex]?.[1]?.[key]
        : lastNStats[key];
      const position = filteredStats.length - yourIndex;
      const hasInitialValues = dataPoint === 0 && nextDataPoint === 0;
      return {
        title: t(...title),
        dataPoint,
        recentAvg: nextDataPoint,
        dataPointMeta:
          !isRecentStats && !hasInitialValues
            ? formatOrdinalNumber(language, position)
            : null,
        dataPosition: !isRecentStats && !hasInitialValues ? position : null,
        Image: !isRecentStats ? (
          <AgentImageContainer $color={borderColor}>
            <AgentImage src={getAgentImage(agentKey)} />
          </AgentImageContainer>
        ) : null,
      };
    });

    return [sections, [comparisonStats]];
  }, [
    match,
    profile,
    lobbyStats,
    lastNStats,
    t,
    recentStats,
    liveMatchComparison,
    agents,
    language,
    playerTeamId,
  ]);

  const queue = match?.queue;
  const isEscalation = queue === "ggteam";
  const isCustom = queue === "custom";
  const isDeathmatch = isQueueDeathmatch(queue);
  const isTeamDeathmatch = isQueueTeamDeathmatch(queue);

  if (!match || !currentPlayer) return null;

  return (
    <SharedMatchLayout
      match={match}
      title={t("common:gameInProgress", "Game is in progress")}
      image={getAgentImage(agentKey, "header")}
      imageLink={`/valorant/profile/${profile?.gameName}-${profile?.tagLine}`}
      underTitle={
        <>
          <span>
            {t("common:queue.name", "{{queue}}", {
              queue: getQueueName(queue),
            })}
          </span>
          <span>{dot_str}</span>
          <Timer startTime={new Date(match.gameStart)} />
        </>
      }
    >
      <MatchPageContainer>
        <MainColumnsContainer
          className={matchRefs.columnsContainerClassName?.()}
        >
          <div className="span-1">
            <Card padding="0" style={{ justifyContent: "start" }}>
              <MapStats
                mapMeta={mapMeta}
                mapData={mapData}
                hideStats={isDeathmatch || isTeamDeathmatch}
              />
              {playerTeamWinPercent && enemyTeamWinPercent ? (
                <>
                  <Divider />
                  <AIWinPrediction
                    playerTeamWinPercent={playerTeamWinPercent}
                    enemyTeamWinPercent={enemyTeamWinPercent}
                  />
                </>
              ) : null}
              {(isDeathmatch || isEscalation || isTeamDeathmatch) && (
                <>
                  <Divider />
                  <LiveMatchRecentStats queue={queue} stats={recentStats} />
                </>
              )}
            </Card>
          </div>
          <div className="span-2">
            {rowData?.length &&
            sections?.length &&
            !isDeathmatch &&
            !isEscalation &&
            !isTeamDeathmatch ? (
              <SharedStatsBreakdown rowData={rowData} sections={sections} />
            ) : null}
            {matchRefs.InContentBanner && <matchRefs.InContentBanner />}
            {match && agents ? (
              <TeamBreakdown
                currentPlayer={currentPlayer}
                players={match.players}
                actId={latestActId}
                allAgents={agents}
                isDeathmatch={isDeathmatch}
                isEscalation={isEscalation}
                isTeamDeathmatch={isTeamDeathmatch}
                isCustom={isCustom}
                queue={queue}
              />
            ) : null}
          </div>
        </MainColumnsContainer>
      </MatchPageContainer>
    </SharedMatchLayout>
  );
}

export default LiveMatch;

export function meta(parameters) {
  return {
    title: ["val:helmet.livegame.title", "Valorant Live Game – Blitz Valorant"],
    description: [
      "val:helmet.livegame.description",
      `${parameters[0]}'s Live game - Valorant Statistics, win rates`,
    ],
  };
}
