import { styled } from "goober";

export const StatsCard = styled("div")`
  padding: var(--sp-4) var(--sp-6);

  .title {
    background: initial;
    padding: 0;
    height: initial;
    margin: 0 0 var(--sp-2) 0;
    color: var(--shade1);
    font-size: var(--sp-3_5e);
    font-style: normal;
    font-weight: 500;
    text-transform: initial;
  }

  .caption {
    color: var(--shade3);
    font-size: var(--sp-3);
    margin-right: var(--sp-1);
  }
`;
